import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { use100vh } from 'react-div-100vh';

import { Seo } from 'components';
import PaymentDialog from './components/PaymentDialog';
import { AppState } from 'state/types';
import { useRouter } from 'apis/history';
import { config } from '../../../config';
import { Events } from 'utils/events';
import {
  useCheckoutData,
  useOnboardingCheckoutData,
  usePageView,
} from 'utils/hooks';
import { getLocalisedProduct } from 'utils/localization';
import { handleError } from 'utils/error';
import { store } from 'index';
import Tracking from 'utils/tracking';
import { Quiz, QuizKey } from 'types/quiz';

const Payments: FC<{ setModalOpen?: any }> = ({ setModalOpen }) => {
  const {
    code,
    selected_plan,
    user,
    geolocation,
    discounted_subs,
    selected_sub_index,
  } = useSelector((state: AppState) => state.user);

  const { goToRegister, navigateBack, goToDiscount } = useRouter();
  const quiz =
    new URLSearchParams(location.search).get(QuizKey.Qz) ?? Quiz.Main;

  const { data } = useCheckoutData();

  usePageView();

  const height = use100vh() ?? 0;

  useEffect(() => {
    if (!code) {
      return;
    }

    Tracking.logEvent({
      event: 'payment-window-clicks',
      funnel: quiz,
      widget: 'payment-window-turned-on',
      product: localisedProduct?.key,
    });
    Tracking.logEvent({
      event: 'email_left',
      client_code: code,
    });
  }, [code]);

  const promoSub = discounted_subs?.[selected_sub_index!];

  const localisedProduct = getLocalisedProduct(selected_plan);

  const createHyrosOrder = async ({ orderId }: { orderId: number }) => {
    try {
      if (config?.ENV === 'development') {
        return;
      }

      const localisedProducts =
        promoSub ?? getLocalisedProduct(store.getState().user.selected_plan);

      const productVisiblePrice = localisedProducts?.hasTrial
        ? localisedProducts.discountedTrialPrice
        : localisedProducts?.finalPrice;
    } catch (e) {
      handleError(e);
    }
  };

  const handleSuccess = async (data: any) => {
    const localisedProducts =
      promoSub ?? getLocalisedProduct(store.getState().user.selected_plan);
    const productVisiblePrice = localisedProducts?.promoPrice
      ? localisedProducts?.promoPrice
      : localisedProducts?.hasTrial
      ? localisedProducts.discountedTrialPrice
      : localisedProducts?.finalPrice;

    try {
      const orderId = String(data?.order_id ?? '');
      const eventData = {
        code: code ?? '',
        email: user?.email,
        location: geolocation?.iso_country?.toLocaleLowerCase(),
        orderId,
        data: {
          currencyCode: localisedProducts?.currency,
          paymentMethod: '',
          transactionId: orderId,
          transactionTotal: parseFloat(productVisiblePrice),
          transactionAffiliation: '',
          transactionProducts: [
            {
              sku: localisedProducts?.sku,
              name: localisedProducts?.name,
              category: 'Subscription',
              price: productVisiblePrice,
              quantity: 1,
            },
          ],
        },
      };
      Events.paymentSuccessful(eventData);
    } catch (e) {
      handleError(e);
    }

    goToRegister();
  };

  const handleOnClose = () => {
    window.history.replaceState(
      null,
      '',
      `${location.pathname.replaceAll(/\/payments/g, '')}${location.search}`,
    );

    Tracking.logEvent({
      event: 'payment-window-clicks',
      funnel: quiz,
      widget: 'payment-window-turned-off',
      product: localisedProduct?.key,
    });

    setModalOpen(false);
    if (data?.discountAvailable) {
      return goToDiscount();
    }
  };

  // Fixes screen jumping when entering credit card number
  useEffect(() => {
    const bodyWrapper = document.getElementById('body-wrapper');
    if (!bodyWrapper) {
      return;
    }

    bodyWrapper.style.height = `${height}px`;

    return () => {
      bodyWrapper.style.height = '';
    };
  }, [height]);

  return (
    <>
      <Seo />

      <PaymentDialog
        selectedProduct={selected_plan}
        isOpen={true}
        onRequestClose={handleOnClose}
        onSuccess={handleSuccess}
        orderSummarySubItems={data?.orderSummarySubItems}
      />
    </>
  );
};

export default Payments;
